<template>
    <div class="player-template-rootnode">
        <div class="meta-artist">
            <span class="the-artist" />
            <span class="the-name" />
        </div>
        <div class="player-box">
            <v-layout align-center>
                <v-flex
                    md2
                    sm2>
                    <v-layout align-center>
                        <v-flex
                            md2
                            sm2
                            xs2
                            offset-md6
                            offset-sm2
                            offset-xs1>
                            <div
                                v-if="layout == 'modal'"
                                ref="isPlay"
                                class="play-pause-btn pause"
                                @click="togglePlayPause($event)">
                                <icon
                                    class="play"
                                    name="audio-play"
                                    size="15" />
                                <icon
                                    class="pause"
                                    name="audio-pause"
                                    size="15" />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex xs10>
                    <v-layout
                        v-resize="handleResize"
                        justify-center
                        align-center>
                        <v-flex xs12>
                            <div class="wrap">
                                <div :id="waveformId" />
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex
                    md2
                    sm2>
                    <v-layout align-center>
                        <v-flex
                            md10
                            sm10
                            xs8
                            offset-md1
                            offset-sm2
                            offset-xs4>
                            <div class="time">
                                {{ currentTime }} / {{ duration }}
                            </div>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
import WaveSurfer from 'wavesurfer';
import Icon from './Icon';

export default {
    components: {
        Icon
    },
    props: ['layout', 'index', 'recording'],
    data() {
        return {
            waveformId: 'waveform' + this.index,
            duration: '00:00',
            currentTime: '00:00',
            counter: 0,
            sources: [
                {
                    name: 'Sample_Magic',
                    title: 'Prophet',
                    src: this.recording,
                    isActive: false
                }
            ],
            isLoaded: false,
            wavesurfer: ''
        };
    },
    updated() {
        this.wavesurfer.drawBuffer();
    },

    async mounted() {
        await this.initWaveSurf(this);
        this.addWaveSurfListeners();
        this.isLoaded = true;
    },

    methods: {
        async initWaveSurf() {
            this.wavesurfer = await WaveSurfer.create({
                container: '#waveform' + this.index,
                barWidth: 1,
                height: 60,
                waveColor: '#c8ebfb',
                progressColor: '#99daf7',
                cursorColor: '#b3ccef',
                backend: 'MediaElement'
            });
            this.wavesurfer.load(this.sources[0].src);
            this.indexPlaying = 0;
        },
        /**
         *  redraw audio wave canvas
         **/
        handleResize() {
            let me = this;
            const { isPlay } = me.$refs;
            if (me.wavesurfer) {
                const currentProgress =
                    me.wavesurfer.getCurrentTime() / me.wavesurfer.getDuration();
                // Reset graph
                me.wavesurfer.empty();
                me.wavesurfer.drawBuffer();
                // Set original position
                me.wavesurfer.seekTo(currentProgress);
                // No stopping audio play
                if (me.hasClass(isPlay, 'play') && currentProgress !== me.wavesurfer.getCurrentTime()) {
                    me.play();
                }
            }
        },
        sformat(s) {
            let minutes = Math.floor(s / 60) % 60; //MINUTES
            let seconds = Math.floor(s) % 60; //SECONDS

            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            return minutes + ':' + seconds;
        },
        addWaveSurfListeners() {
            let me = this;
            this.wavesurfer.on('ready', function() {
                me.duration = me.sformat(me.wavesurfer.getDuration());
                me.wavesurfer.on('audioprocess', function(time) {
                    if (me.counter < Math.round(time)) {
                        me.counter = Math.round(time);
                        me.currentTime = me.sformat(me.wavesurfer.getCurrentTime());
                    }
                });
            });
        },
        getSurfer() {
            return this.wavesurfer;
        },
        play() {
            this.wavesurfer.play();
        },
        pause() {
            this.wavesurfer.pause();
        },
        hasClass(target, className) {
            return new RegExp('(\\s|^)' + className + '(\\s|$)').test(
                target.className
            );
        },
        togglePlayPause(event) {
            if (this.hasClass(event.currentTarget, 'pause')) {
                event.currentTarget.classList.add('play');
                event.currentTarget.classList.remove('pause');
                this.play();
            } else {
                event.currentTarget.classList.add('pause');
                event.currentTarget.classList.remove('play');
                this.pause();
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.player-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .wrap {
        border-bottom: 1px solid #b3ccef;
        border-left: 1px solid #b3ccef;
        min-width: 65%;
    }
}

.btn-prev,
.btn-next,
.play-pause-btn {
    display: inline-block;
    padding: 10px;
    &:hover {
        cursor: pointer;
    }
}

.play-pause-btn.play {
    .play {
        display: none;
    }

    .pause {
        display: inline-block;
    }
}

.play-pause-btn.pause {
    .play {
        display: inline-block;
    }

    .pause {
        display: none;
    }
}

.fa-backward {
    margin-left: 0.4em;
    margin-top: 0.1em;
}

.fa-forward {
    margin-left: 0.6em;
    margin-top: 0.1em;
}

.fa-pause,
.fa-play {
    cursor: pointer;
    margin-left: 0.7em;
    margin-top: 0.1em;
}

.playlist {
    .demo-song {
        background-color: #b3ccef;
        color: #fff;
        padding: 1em;
    }

    .demo-song.active {
        background-color: #b3ccef;
    }
}

.time {
    margin: 0;
    color: #b3ccef;
}

.player-template-rootnode {
    width: 100%;
}

canvas {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
</style>
