<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px">
        <template #activator="{ on }">
            <v-text-field
                v-model="date"
                :label="label"
                class="styled-field"
                readonly
                :rules="rules"
                v-on="on" />
        </template>
        <v-date-picker
            v-model="date"
            :min="today"
            @input="menu = false" />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: 'Date'
        },
        rules: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            today: this.$moment().format('YYYY-MM-DD'),
            menu: false,
            date: ''
        };
    },
    watch: {
        value() {
            if (this.date != this.value) {
                this.date = this.value;
            }
        },
        date() {
            this.$emit('input', this.date);
        }
    },
    created() {
        this.date = this.value;
    }
};
</script>
