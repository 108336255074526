<template>
    <styled-interface>
        <template #interface-toolbar>
            <dealer-picker-global class="flex md3 xs12 mr-5" />

            <date-range-picker-presets class="flex md3 xs12 mr-5" />
        </template>

        <template #interface-heading>
            Leads
        </template>


        <div
            v-if="!currentDealerId || loadingLeads"
            class="text-xs-center">
            <loader />
        </div>

        <ads-upsell
            v-else-if="currentDealerId && !dealerHasAdProduct && !loadingLeads && !leads.length" />


        <styled-card v-else-if="(dealerHasAdProduct || leads.length) && !loadingLeads">
            <template #heading>
                Leads
            </template>
            <template #action-buttons>
                <action-button
                    icon="download"
                    :loading="leadsExportLoading"
                    @click="exportCSV()">
                    Export CSV
                </action-button>
                <action-button
                    v-if="!userIsClient"
                    icon="subscribe"
                    @click="addLeadSlideoutOpen = true">
                    Create Lead
                </action-button>
            </template>

            <add-lead-slideout
                id="lead-form"
                v-model="addLeadSlideoutOpen" />


            <styled-slideout
                v-model="leadInfoSlideoutOpen">
                <div
                    v-if="currentLead"
                    class="pa-5">
                    <h2 class="mb-4">
                        Lead Details: {{ currentLead.customer_name }}
                    </h2>
                    <div class="layout">
                        <data-field
                            label="Source"
                            class="flex xs12 md6 mb-3">
                            {{ currentLead.lead_platform.name }}
                        </data-field>

                        <data-field
                            label="Date"
                            class="flex xs12 md6 mb-3">
                            <time
                                class="txt"
                                :datetime="currentLead.lead_created_at.date">
                                {{ $moment.utc(currentLead.lead_created_at.date).local().format('MMM Do YYYY, h:mma') }}
                            </time>
                        </data-field>
                    </div>

                    <div class="layout">
                        <data-field
                            label="Lead Info"
                            class="flex xs12 md6 mb-3">
                            <div>{{ currentLead.customer_name }}</div>
                            <div>{{ currentLead.customer_phone_number }}</div>
                            <div>{{ currentLead.customer_email }}</div>
                        </data-field>

                        <data-field
                            v-if="currentLead.vehicle !== null"
                            label="Vehicle Info"
                            class="flex xs12 md6 mb-4">
                            <div>{{ currentLead.vehicle.display_name }}</div>
                            <div>Stock #:{{ currentLead.vehicle.stock_number }}</div>
                        </data-field>
                    </div>

                    <data-field
                        v-if="currentLead.data && currentLead.data.internal_comments"
                        label="Internal Comments"
                        class="mb-4">
                        {{ currentLead.data.internal_comments }}
                    </data-field>

                    <template v-if="currentLead.data && currentLead.data.field_data">
                        <h4 class="mb-3">
                            Form Data
                        </h4>

                        <div class="layout wrap">
                            <!--
                                Capalitize the first letter in the field name string and
                                remove all underscores and replace them with spaces
                            -->
                            <data-field
                                v-for="(field, index) in currentLead.data.field_data"
                                :key="`field-${index}`"
                                :label="(field.name.charAt(0).toUpperCase() + field.name.slice(1)).replace(/_/g, ' ')"
                                class="flex xs12 md6 mb-3">
                                <div
                                    v-for="(value, index2) in field.values"
                                    :key="`value-${value}-${index2}`">
                                    {{ value }}
                                </div>
                            </data-field>
                        </div>
                    </template>

                    <!-- Show lead form data for PageFlow leads -->
                    <template v-if="currentLead.data && currentLead.lead_platform.id === 6">
                        <h4 class="mb-3">
                            Form Data
                        </h4>

                        <div class="layout wrap">
                            <data-field
                                v-for="(value, key) in currentLead.data"
                                :key="`field-${key}`"
                                :label="key"
                                class="flex xs12 md6 mb-3">
                                <div>
                                    {{ value }}
                                </div>
                            </data-field>
                        </div>
                    </template>
                </div>
            </styled-slideout>

            <v-layout
                v-if="!leads.length"
                row
                justify-center
                align-center>
                <div class="pa-5">
                    No leads could be found for the provided timeframe.  Please try another selection.
                </div>
            </v-layout>

            <!--Table block-->
            <div
                v-if="leads.length"
                class="leads-all">
                <div class="leads-carousel px-3">
                    <!-- head block-->
                    <v-container
                        fluid
                        class="ma-0 pa-0 pb-2">
                        <v-layout
                            row
                            justify-start
                            align-center
                            wrap
                            class="ma-0">
                            <v-flex xs2>
                                <v-layout>
                                    <v-flex
                                        xs10
                                        offset-md1>
                                        Source
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex xs3>
                                <div>Date</div>
                            </v-flex>
                            <v-flex xs4>
                                <div>Lead Info</div>
                            </v-flex>
                            <v-flex xs3>
                                <div>Vehicle Info</div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <!--End head block-->

                    <!--Table body block-->
                    <v-container
                        class="mask ma-0 pa-0"
                        fluid>
                        <div
                            v-for="(post, index) in leads"
                            :key="post.id"
                            class="post active py-3 lead-row">
                            <v-layout
                                row
                                justify-start
                                align-center
                                class="ma-0 pa-0"
                                @click="handleLeadRowClick(post)">
                                <v-flex xs2>
                                    <div class="icon-box">
                                        <v-btn
                                            v-show="post.call_recording != null"
                                            class="btn open"
                                            @click.stop="post.visibleSlider = !post.visibleSlider">
                                            <v-icon color="light-blue lighten-4">
                                                play_circle_outline
                                            </v-icon>
                                            <!--<svg v-show="post.call_recording == null" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.846 24.938l-5.06-5.432-9.874 5.432 10.86-11.605 5.185 5.432 9.75-5.432-10.861 11.605zM19.87 0C8.897 0 0 8.291 0 18.519c0 5.827 2.89 11.026 7.405 14.42V40l6.766-3.737c1.806.503 3.719.774 5.7.774 10.974 0 19.87-8.291 19.87-18.518C39.742 8.29 30.846 0 19.872 0z" fill="#BBE8FF" fill-rule="evenodd"/>
                                            </svg>-->
                                        </v-btn>
                                        <v-icon
                                            v-show="post.call_recording == null"
                                            color="light-blue lighten-4">
                                            mail_outline
                                        </v-icon>
                                        <span class="txt">{{ fbToMeta(post.lead_platform.name) }}</span>
                                    </div>
                                </v-flex>
                                <v-flex xs3>
                                    <div class="info-col">
                                        <time
                                            class="txt"
                                            :datetime="post.lead_created_at.date">
                                            {{ $moment.utc(post.lead_created_at.date).local().format('MMM Do YYYY, h:mma') }}
                                        </time>
                                    </div>
                                </v-flex>
                                <v-flex xs4>
                                    <div class="info-col">
                                        <span class="txt font-weight-bold">{{ post.customer_name }}</span>
                                        <span class="txt">{{ post.customer_phone_number }}</span>
                                        <span class="txt">{{ post.customer_email }}</span>
                                        <span
                                            v-if="getAppointment(post)"
                                            class="txt">
                                            Appt: {{ getAppointment(post) }}
                                        </span>
                                    </div>
                                </v-flex>
                                <v-flex xs3>
                                    <ul
                                        v-if="post.vehicle !== null"
                                        class="info-txt">
                                        <li class="txt">
                                            {{ post.vehicle.display_name }}
                                        </li>
                                        <li class="txt">
                                            Stock #:{{ post.vehicle.stock_number }}
                                        </li>
                                    </ul>
                                </v-flex>
                            </v-layout>
                            <transition
                                v-if="post.call_recording != null"
                                name="collapse"
                                @enter="start"
                                @after-enter="end"
                                @before-leave="start"
                                @after-leave="end">
                                <div
                                    v-if="post.visibleSlider"
                                    class="collapse__wrapper"
                                    :ind-key="index">
                                    <div class="media-popup pt-3 pb-3">
                                        <wavesurfer-player
                                            :ref="`waveplayer-${index}`"
                                            layout="modal"
                                            :index="index"
                                            :recording="post.call_recording.recording" />
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </v-container>
                </div>
                <!--pagination-->
                <div class="text-xs-center dealers-onboarding-pagination py-4">
                    <template v-if="raw.meta && leads.length">
                        <button
                            class="first"
                            @click="gotoFirstPage">
                            <i class="v-icon material-icons light-grey">first_page</i>
                        </button>
                        <v-pagination
                            v-model="pagination.page"
                            :length="raw.meta.last_page"
                            :total-visible="6"
                            prev-icon="mdi-chevron-left"
                            next-icon="mdi-chevron-right"
                            @input="onPagination" />
                        <button class="last">
                            <i
                                class="v-icon material-icons light-grey"
                                @click="gotoLastPage">last_page</i>
                        </button>
                    </template>
                </div>
                <!--END pagination-->
            </div>
            <!--Table block-->
        </styled-card>
    </styled-interface>
</template>

<script>
import DataField from '../globals/DataField';
import AddLeadSlideout from './AddLeadSlideout';
import ActionButton from '../globals/ActionButton';
import StyledInterface from '../globals/StyledInterface';
import StyledCard from '../globals/StyledCard';
import DateRangePickerPresets from '../globals/DateRangePickerPresets';
import StyledSlideout from '../globals/StyledSlideout';
import AdsUpsell from '../globals/AdsUpsell';
import DealerPickerGlobal from '../globals/DealerPickerGlobal';
import Loader from '../globals/Loader.vue';
import WavesurferPlayer from '../globals/audio.vue';
import exportToCsv from '../../helpers/exportToCsv';
import { get } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { fbToMeta } from '@/helpers/fbToMeta';

export default {
    components: {
        DataField,
        StyledSlideout,
        AddLeadSlideout,
        ActionButton,
        AdsUpsell,
        DateRangePickerPresets,
        DealerPickerGlobal,
        Loader,
        StyledCard,
        StyledInterface,
        WavesurferPlayer
    },
    title: 'Leads',
    data() {
        return {
            currentLead: null,
            leadInfoSlideoutOpen: false,
            addLeadSlideoutOpen: false,
            pagination: {
                sortBy: 'lead_created_at',
                descending: true
            },
            raw: [],
            page: 1,
            headers: [
                {
                    text: 'Source',
                    align: 'left',
                    sortable: false,
                    width: '15%'
                },
                {
                    text: 'Date',
                    value: 'lead_created_at',
                    align: 'left',
                    width: '20%'
                },
                {
                    text: 'Lead Info',
                    align: 'left',
                    sortable: false,
                    width: '40%'
                },
                {
                    text: 'Vehicle Info',
                    align: 'left',
                    sortable: false,
                    width: '25%'
                }
            ],
            leads: [],
            leadsExport: [],
            leadsExportLoading: false,
            errors: [],
            loadingLeads: true,
            typingTimer: null,
            fbToMeta
        };
    },
    computed: {
        ...mapGetters([
            'dealerHasAdProduct',
            'userIsClient'
        ]),
        ...mapState({
            currentDealerId: (state) => state.dealer.currentDealerId,
            user: (state) => state.user.user,
            dateRange: (state) => state.metrics.dateRange
        })
    },
    watch: {
        dateRange: {
            handler(newRange, oldRange) {
                if (oldRange !== newRange) {
                    this.getLeads();
                }
            },
            deep: true
        },
        currentDealerId() {
            this.getLeads();
        }
    },
    created() {


        this.$http.cancel();
        this.getLeads();
    },
    methods: {
        async getLeads() {

            // Only retrieve leads if there's a dealer and dateRange
            if (!this.currentDealerId || !this.dateRange) {
                return;
            }

            this.loadingLeads = true;

            this.leads = [];

            try {
                const response = await this.getLeadsData();
                this.leads = response.data;
                this.raw = response;
            } catch (error) {
                console.error('Error retrieving leads',  error);
            } finally {
                this.loadingLeads = false;
            }
        },
        async exportCSV() {

            // If there's no leads there's nothing to do
            if (!this.leads.length) {
                return;
            }

            try {

                // Reset the leads export
                this.leadsExport = [];
                this.leadsExportLoading = true;

                let complete = false;
                let page = 1;
                let response;

                // Continue until complete
                while (!complete) {
                    response = await this.getLeadsData(page);

                    this.leadsExport = this.leadsExport.concat(response.data);

                    // Load the require until the current page equals the last page
                    if (response.meta.current_page === response.meta.last_page) {
                        complete = true;
                    }

                    // Increment the page
                    page++;
                }

                // Collect all custom fields to ensure every exported
                // record has a full set of properties (a requirement of
                // the library we're currently using)
                const customFields = {};
                this.leadsExport.forEach(lead => {
                    if (lead.data?.field_data?.length) {
                        lead.data?.field_data.forEach(field => {

                            // Store the label for use downstream
                            field.label = this.getFieldLabel(field);

                            // Default the field to an empty string;
                            customFields[field.label] = '';
                        });
                    }
                });

                // Prep for export
                this.leadsExport = this.leadsExport.map(lead => {

                    let fields = {
                        id: lead.id,
                        date: this.$moment.utc(lead.lead_created_at.date).local().format('YYYY-MM-DD hh:mm:ss') || '',
                        source: get(lead,'lead_platform.name') || '',
                        email: get(lead, 'customer_email') || '',
                        phone: get(lead, 'customer_phone_number') || '',
                        name: get(lead, 'customer_name') || '',
                        vehicle_id: get(lead, 'vehicle.id') || '',
                        vin: get(lead, 'vehicle.vin') || '',
                        stock_number: get(lead, 'vehicle.stock_number') || '',
                        make: get(lead, 'vehicle.make_name') || '',
                        model: get(lead, 'vehicle.model_name') || '',
                        year: get(lead, 'vehicle.year') || '',
                        internal_comments: get(lead, 'data.internal_comments') || ''
                    };

                    // Loop through the fields once again for the actual export
                    if (lead.data?.field_data?.length) {
                        const leadCustomFields = {};
                        lead.data?.field_data.forEach(field => {

                            // Ignore anything without values
                            if (!field?.values?.length) {
                                return;
                            }

                            // Use the label created upstream
                            leadCustomFields[field.label] = field.values.join(', ');
                        });

                        // Now combine the fields with custom fields
                        fields = {
                            ...fields,
                            ...customFields, // Default the custom fields for coverage
                            ...leadCustomFields // Override with local values
                        };
                    }

                    return fields;
                });

                exportToCsv(`leads_${new Date().getTime()}`, this.leadsExport);
            } catch (error) {
                // @todo show error
                console.error(error);
            } finally {
                this.leadsExportLoading = false;
            }
        },
        getFieldLabel(field) {
            return field.name
                .replace(/(?:^|\.?)([A-Z ])/g, (x, y) => '_' + y.toLowerCase())
                .replace(/^_/, '');
        },
        async getLeadsData(page) {

            // Copy pagination object so we can manipulate it
            const pagination = { ...this.pagination };

            // Use the externally provided page if configured
            pagination.page = page || this.pagination.page;

            const params = [
                this.$moment(this.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD hh:mm:ss'),
                this.$moment(this.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD hh:mm:ss'),
                pagination,
                '',
                this.currentDealerId
            ];

            const response = await this.$apiRepository.getLeads(...params);

            const leadResponse = response.data;
            // Default the slider for each result to false
            leadResponse.data.forEach(a => a.visibleSlider = false);

            return leadResponse;
        },
        onPagination() {
            this.getLeads();
        },
        gotoFirstPage() {
            this.pagination.page = 1;
            this.getLeads();
        },
        gotoLastPage() {
            this.pagination.page = this.raw.meta.last_page;
            this.getLeads();
        },
        start(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        end(el) {
            el.style.height = '';
            if (!this.hasClass(el, 'open')) {
                el.classList.add('open');
            } else {
                el.classList.remove('open');
            }
        },
        hasClass(target, className) {
            return new RegExp('(\\s|^)' + className + '(\\s|$)').test(
                target.className
            );
        },
        handleLeadRowClick(lead) {
            this.currentLead = lead;
            this.leadInfoSlideoutOpen = true;
        },
        getAppointment(lead) {
            if (!lead.data?.field_data?.length) {
                return null;
            }

            const appointment = lead.data.field_data.find(field => field.name == 'Appointment');

            if (!appointment?.values?.length) {
                return null;
            }

            return this.$moment(appointment.values[0]).format('MMM Do YYYY, h:mma');
        }
    }
};
</script>
<style lang="scss" scoped>

.lead-row {
    &:hover {
        background-color: $alabaster;
        cursor: pointer;
    }
}

.leads-all {
    color: $gray !important;
    padding: 3rem 2rem;
}
</style>

