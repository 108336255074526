<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Create Manual Lead</h2>
            <p class="mb-4">
                Submit the form below with the relevant information to manually 
                create a lead for {{ currentDealer.name }}.
            </p>
            <v-alert
                v-model="complete"
                type="success"
                class="mb-4">
                The lead was successfully submitted!  You can close this
                dialog or continue submitting leads below
            </v-alert>
            <v-alert
                v-model="error"
                type="error"
                class="mb-4">
                {{ error }}
            </v-alert>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="handleSubmit">
                <v-text-field
                    v-model="customerName"
                    :rules="requiredRules"
                    label="Customer Name"
                    class="styled-field mb-3" />
                <v-text-field
                    v-model="customerEmail"
                    :rules="emailRules"
                    label="Customer Email"
                    class="styled-field mb-3" />
                <v-text-field
                    v-model="customerPhone"
                    label="Customer Phone"
                    class="styled-field mb-3" />
                <v-radio-group
                    v-model="vehicleReferenceType"
                    :rules="requiredRules"
                    class="styled-radio mb-3"
                    row>
                    <v-radio
                        label="Search Vehicle"
                        :value="VEHICLE_REFERENCE_TYPE_SEARCH"
                        color="primary"
                        class="mb-0" />
                    <v-radio
                        label="Manually Enter Vehicle"
                        :value="VEHICLE_REFERENCE_TYPE_MANUAL"
                        color="primary" />
                </v-radio-group>
                <div v-if="vehicleReferenceType == VEHICLE_REFERENCE_TYPE_MANUAL">
                    <div class="layout mb-3">
                        <v-text-field
                            v-model="vehicleYear"
                            label="Year"
                            :rules="requiredRules"
                            class="styled-field mr-3" />
                        <v-text-field
                            v-model="vehicleMake"
                            label="Make"
                            :rules="requiredRules"
                            class="styled-field mr-3" />
                        <v-text-field
                            v-model="vehicleModel"
                            label="Model"
                            :rules="requiredRules"
                            class="styled-field" />
                    </div>
                    <div class="layout">
                        <div>
                            <v-select
                                v-model="vehicleCondition"
                                label="Condition"
                                :items="[{
                                    text: 'Used',
                                    value: VEHICLE_CONDITION_USED 
                                },{
                                    text: 'New',
                                    value: VEHICLE_CONDITION_NEW
                                }]"
                                class="styled-field mr-3" />
                        </div>
                        <v-text-field
                            v-model="vehicleVin"
                            label="VIN"
                            :rules="vinRules"
                            class="styled-field mb-3" />
                    </div>
                </div>
                <v-autocomplete
                    v-if="vehicleReferenceType == VEHICLE_REFERENCE_TYPE_SEARCH"
                    v-model="vehicle"
                    :search-input.sync="vehicleSearch"
                    :items="vehicles"
                    item-text="name"
                    item-value="id"
                    :loading="loadingVehicles ? 'primary' : false"
                    class="styled-field mb-3"
                    label="Vehicle"
                    placeholder="Enter keywords..."
                    hide-selected
                    hide-no-data
                    no-filter
                    clearable
                    return-object
                    @keyup="onSearchVehicles">
                    <template #selection="{ item }">
                        {{ item.year }} {{ item.make_name }} {{ item.model_name }} {{ item.trim_name }}
                    </template>
                    <template #item="{ item }">
                        <v-list-tile-avatar v-if="item.images.length">
                            <img :src="item.images[0]">
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                            <v-list-tile-title>{{ item.year }} {{ item.make_name }} {{ item.model_name }} {{ item.trim_name }}</v-list-tile-title>
                            <v-list-tile-sub-title class="grey--text text--lighten-2">
                                {{ item.vin }} ({{ item.stock_number }})
                            </v-list-tile-sub-title>
                        </v-list-tile-content>
                    </template>
                </v-autocomplete>
                <v-checkbox
                    v-model="hasAppointment"
                    color="primary"
                    class="styled-checkbox styled-checkbox-small mb-3"
                    label="Add appointment" />
                <div 
                    v-if="hasAppointment"
                    class="layout mb-3">
                    <div class="flex grow mr-3">
                        <date-field
                            v-model="appointmentDate"
                            :rules="requiredRules" />
                    </div>
                    <div class="flex grow mr-3">
                        <v-text-field
                            v-model="appointmentTime"
                            :rules="[...requiredRules, ...timeRules]"
                            return-masked-value
                            mask="##:##"
                            label="Time"
                            class="styled-field" />
                    </div>
                    <div class="flex shrink">
                        <v-select
                            v-model="appointmentTimeAmPm"
                            :rules="requiredRules"
                            :items="['AM', 'PM']"
                            label="AM/PM"
                            class="styled-field" />
                    </div>
                </div>
                <v-textarea
                    v-model="comments"
                    class="styled-field mb-3"
                    label="Comments" />
                <v-checkbox
                    v-model="hasInternalComments"
                    color="primary"
                    class="styled-checkbox styled-checkbox-small mb-3"
                    label="Add internal comments" />
                <v-textarea
                    v-if="hasInternalComments"
                    v-model="internalComments"
                    :rules="requiredRules"
                    class="styled-field mb-3"
                    label="Internal Comments" />
                <v-checkbox
                    v-model="customizeLeadSource"
                    color="primary"
                    class="styled-checkbox styled-checkbox-small mb-3"
                    label="Customize lead source" />
                <template v-if="customizeLeadSource">
                    <v-text-field
                        v-model="providerName"
                        label="Provider Name"
                        class="styled-field mb-3" />
                    <v-text-field
                        v-model="providerService"
                        label="Provider Service"
                        class="styled-field mb-3" />
                </template>
                <div class="text-xs-center my-4">
                    <styled-button
                        :loading="loadingSubmit">
                        CREATE LEAD
                    </styled-button>
                </div>
                <div class="text-xs-center grey--text">
                    <em>
                        Upon submission this lead will be sent to any 
                        configured lead destinations and stored for reporting.
                    </em>
                </div>
            </v-form>
        </div>
    </styled-slideout>
</template>

<script>
import DateField from '@/components/globals/fields/DateField';
import StyledButton from '@/components/globals/StyledButton';
import StyledSlideout from '@/components/globals/StyledSlideout';
import { requiredRules, emailRules, vinRules, timeRules } from '@/helpers/validationRules';
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default {
    components: {
        DateField,
        StyledButton,
        StyledSlideout
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            hasAppointment: false,
            appointmentDate: '',
            appointmentTime: '',
            appointmentTimeAmPm: null,
            complete: false,
            error: null,
            loadingSubmit: false,
            valid: false,
            customerName: '',
            customerEmail: '',
            customerPhone: '',
            customizeLeadSource: false,
            providerName: '',
            providerService: '',
            vehicleSearch: '',
            vehicleReferenceType: '',
            vehicleVin: '',
            vehicleMake: '',
            vehicleModel: '',
            vehicleYear: '',
            vehicleCondition: 'used',
            vehicle: null,
            comments: '',
            hasInternalComments: false,
            internalComments: '',
            vehicles: [],
            loadingVehicles: false,
            requiredRules,
            emailRules,
            vinRules,
            timeRules: timeRules('US'),
            // Constants
            VEHICLE_REFERENCE_TYPE_SEARCH: 'search',
            VEHICLE_REFERENCE_TYPE_MANUAL: 'manual',
            VEHICLE_CONDITION_USED: 'used',
            VEHICLE_CONDITION_NEW: 'new'
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    watch: {
        currentDealer() {
            this.setDefaults();
        }
    },
    created() {
        this.vehicleCondition = this.VEHICLE_CONDITION_USED;
        this.vehicleReferenceType = this.VEHICLE_REFERENCE_TYPE_SEARCH;
        this.setDefaults();
    },
    methods: {
        setDefaults() {
            this.providerName = this.currentDealer.configuration?.override_xml_provider_name || this.currentDealer.agency.xml_provider_name;
            this.providerService = this.currentDealer.agency.configuration?.override_service_name || 'User Entered Lead';
        },
        async handleSubmit() {
            try {
                this.error = null;
                this.complete = false;

                this.$refs.form.validate();

                // Ensure the form validates
                await this.$nextTick();

                if (!this.valid) {
                    return;
                }

                this.loadingSubmit = true;

                const uuid = uuidv4();

                const fieldData = [{
                    name: 'Comments',
                    values: [ this.comments ]
                }];

                if (this.hasAppointment) {
                    const dateString = `${this.appointmentDate} ${this.appointmentTime}${this.appointmentTimeAmPm}`;

                    fieldData.push({
                        name: 'Appointment',
                        values: [ 
                            moment(dateString, 'YYYY-MM-DD h:mmA').format('YYYY-MM-DD HH:mm:ss') 
                        ]
                    });
                }

                const customData = {
                    field_data: fieldData
                };

                // Allow users to override the provider name and service
                // Note that the UI currently lines up defaults here
                customData.adf_xml = {
                    provider: {
                        name: this.providerName,
                        service: this.providerService
                    }
                };

                if (this.internalComments) {
                    customData.internal_comments = this.internalComments;
                }

                // If the user has manually typed in a VIN then we need to use the 
                // purchase_vehicle object which will manually pass details through
                if (this.vehicleReferenceType == this.VEHICLE_REFERENCE_TYPE_MANUAL 
                    && this.vehicleVin.length) {
                    customData.purchase_vehicle = {
                        model_name: this.vehicleModel,
                        make_name: this.vehicleMake,
                        year: this.vehicleYear,
                        vin: this.vehicleVin,
                        used: (this.vehicleCondition == this.VEHICLE_CONDITION_USED)
                    };
                }

                const data = {
                    dealer_id: this.currentDealer.id,
                    lead_platform: 'API',
                    remote_id: 'dash-lead-' + uuid,
                    customer_name: this.customerName,
                    customer_email: this.customerEmail,
                    customer_phone_number: this.customerPhone,
                    data: customData
                };

                // If the user has searched existing inventory for a VIN
                // then we can use the top-level vin field for reference
                if (this.vehicleReferenceType == this.VEHICLE_REFERENCE_TYPE_SEARCH 
                    && this.vehicle) {
                    data.vin = this.vehicle.vin;
                }

                await this.$apiRepository.createLead(data);

                this.$emit('complete');
                this.complete = true;
                
                this.reset();
                
            } catch (error) {
                console.error('Error submitting lead', error.response || error);
                this.error = 'There was an error submitting this lead.  Please try again for contact support if the problem continues.';
            } finally {
                this.loadingSubmit = false;
            }
        },
        reset() {
            this.hasInternalComments = false;
            this.internalComments = '';
            this.hasAppointment = false;
            this.appointmentDate = '';
            this.appointmentTime = '';
            this.appointmentTimeAmPm = null;
            this.customerName = '';
            this.customerEmail = '';
            this.customerPhone = '';
            this.leadSource = '';
            this.vehicleSearch = '';
            this.vehicleVin = '';
            this.vehicleMake = '';
            this.vehicleModel = '';
            this.vehicleYear = '';
            this.vehicleCondition = this.VEHICLE_CONDITION_USED;
            this.vehicleReferenceType = this.VEHICLE_REFERENCE_TYPE_SEARCH;
            this.vehicle = null;
            this.comments = '';
            this.customizeLeadSource = false;
            this.setDefaults();
            this.$refs.form.resetValidation();
        },
        onSearchVehicles: debounce(async function() {
            try {
                if (!this.vehicleSearch) {
                    return;
                }

                this.loadingVehicles = true;

                const response = await this.$http.post(`/dealers/${this.currentDealer.id}/vehicles/search`, {
                    query: {
                        bool: {
                            must: [{
                                multi_match: {
                                    query: this.vehicleSearch,
                                    fields: ['vin^3', 'stock_number^3', 'year', 'make_name', 'model_name', 'body_name'],
                                    lenient: true
                                }
                            }]
                        }
                    },
                    size: 50
                });
                
                this.vehicles = response.data.hits.hits.map(hit => hit._source);
                
            } finally {
                this.loadingVehicles = false;
            }
        }, 300)
    }
};
</script>